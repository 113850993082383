<template>
  <Form :model="formData" :inline="true" :label-colon="true">
    <div style="width: 320px !important; display: inline-block">
      <FormItem label="档案编号" style="width: 100%">
        <Input
          :maxlength="20"
          v-model.trim="formData.archiveDetailVo.archiveNum"
          :disabled="true"
          style="width: 200px"
        ></Input>
      </FormItem>
    </div>
    <FormItem label="所属社区" style="width: calc(100% - 400px)">
       <span slot="label" class="validator">所属社区:</span>
      <Select v-model="formData.archiveDetailVo.orgCode" placeholder="请选择所属社区"  style="width:200px" :disabled="otherOrgName ? true : false" @on-change="changeOrgCode">
        <Option v-for="(item,index) in orgCodeList" :data-label="item.label" :value="item.value" :key="index">{{ item.label }}</Option>
       </Select>
      <Checkbox
        v-model="otherOrgName"
        style="margin: 0 10px 0 20px"
        @on-change="otherOrgNameFn"
        >所属其他</Checkbox
      >
      <Input
        style="width: 200px"
        :maxlength="20"
        v-model.trim="formData.archiveDetailVo.orgName"
        placeholder="请输入所属其他社区"
        :disabled="(!otherOrgName && formData.archiveDetailVo.orgCode) ? true : false"
      ></Input>
    </FormItem>
    <Divider style="margin: 15px 0" />
    <div class="content">
      <h2 style="text-align: center">基本信息</h2>
      <FormItem label="人员类别" style="width: 100%">
         <span slot="label" class="validator">人员类别:</span>
        <RadioGroup v-model="formData.archiveDetailVo.difficultType">
          <Radio label="1">低保低收入对象</Radio>
          <Radio label="2">特困人员</Radio>
          <Radio label="3">留守老年人</Radio>
          <Radio label="4">独居孤寡老人</Radio>
          <Radio label="5">困境儿童</Radio>
          <Radio label="6">贫困重度残疾人</Radio>
          <Radio label="7">其他</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="建档社工" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="formData.archiveDetailVo.staffName"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="建档时间" style="width: 24%">
           <span slot="label" class="validator">建档时间:</span>
        <DatePicker
        v-model="formData.archiveDetailVo.archiveCreate"
          type="date"
          placeholder="请选择建档时间"
          style="width: calc(100% - 100px)"
        ></DatePicker>
      </FormItem>
      <FormItem label="所属部门" style="width: 24%">
        <Select v-model="formData.archiveDetailVo.deptCode" placeholder="请选择所属部门"  style="width:200px">
        <Option v-for="(item,index) in departmentList"  :data-label="item.label" :value="item.value" :key="index">{{ item.label }}</Option>
       </Select>
      </FormItem>

      <Divider style="margin: 15px 0" />

      <FormItem label="姓名" style="width: 24%">
       <span slot="label" class="validator">姓名:</span>
        <Input
          :maxlength="20"
          v-model.trim="formData.userNeedyVo .name"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="性别" style="width: 24%">
          <span slot="label" class="validator">性别:</span>
        <RadioGroup v-model="formData.userNeedyVo .sex">
          <Radio label="1">男</Radio>
          <Radio label="2">女</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="年龄" style="width: 24%">
        <Input
          :maxlength="20"
          :disabled="true"
          v-model.trim="formData.archiveDetailVo.age"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="职业" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="formData.userDetailVo.profession"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="民族" style="width: 24%">
        <Select v-model="formData.userNeedyVo.national" style="width: calc(100% - 100px)">
          <Option
            v-for="(item,index) in nationList"
            :value="item.value"
            :key="index"
            >{{ item.label }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="联系电话" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="formData.archiveDetailVo.mobile"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="身份证号码" style="width: 24%">
         <span slot="label" class="validator">身份证号码:</span>
        <Input
          :maxlength="20"
          v-model.trim="formData.archiveDetailVo.idNum"
          style="width: calc(100% - 120px)"
        ></Input>
      </FormItem>
      <FormItem label="户籍" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="formData.userNeedyVo.hometown"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="户籍地址" style="width: 100%">
        <Input
          :maxlength="50"
          v-model.trim="formData.userNeedyVo.idNumAddr"
          style="width: calc(100% - 150px)"
        ></Input>
      </FormItem>
      <FormItem label="现居住地址" style="width: 100%">
        <Input
          :maxlength="50"
          v-model.trim="formData.userDetailVo.addrCurrent"
          style="width: calc(100% - 155px)"
        ></Input>
      </FormItem>
      <FormItem label="监护人/紧急联系人" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="formData.userContactsVo.name"
          style="width: calc(100% - 150px)"
        ></Input>
      </FormItem>
      <FormItem label="紧急联系方式" style="width: 50%">
        <Input
          :maxlength="50"
          v-model.trim="formData.userContactsVo.mobile"
          style="width: calc(100% - 150px)"
          placeholder="多个手机号请用逗号隔开"
        ></Input>
      </FormItem>
      <FormItem label="人户分离情况" style="width: 100%">
        <RadioGroup v-model="formData.userDetailVo.registeredUser">
          <Radio label="1">户籍和人都在当地</Radio>
          <Radio label="2">人不在当地</Radio>
          <Radio label="3">户籍不在当地</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="教育程度" style="width: 100%">
        <RadioGroup v-model="formData.userDetailVo.eduDegree">
          <Radio label="0">未知</Radio>
          <Radio label="1">小学</Radio>
          <Radio label="2">初中</Radio>
          <Radio label="3">高中</Radio>
          <Radio label="4">专科</Radio>
          <Radio label="5">本科</Radio>
          <Radio label="6">硕士及以上</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="婚姻状况" style="width: 100%">
        <RadioGroup v-model="formData.userDetailVo.marital">
          <Radio label="0">未知</Radio>
          <Radio label="1">未婚</Radio>
          <Radio label="2">已婚</Radio>
          <Radio label="3">离异</Radio>
          <Radio label="4">丧偶</Radio>
          <Radio label="5">离异再婚</Radio>
          <Radio label="6">丧偶再婚</Radio>
           <Radio label="7">同居</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="居住状况" style="width: 100%">
        <RadioGroup v-model="formData.userHouseVo.liveType">
          <Radio label="1">独居</Radio>
          <Radio label="2">与亲人同居</Radio>
          <Radio label="3">与朋友同住</Radio>
          <Input
            v-if="formData.userHouseVo.liveType==2 ||formData.userHouseVo.liveType==3"
            :maxlength="30"
            v-model.trim="formData.userHouseVo.liveTypeDesc"
          ></Input>
        </RadioGroup>
      </FormItem>
      <FormItem label="居所类别" style="width: 100%">
        <RadioGroup v-model="formData.userHouseVo.houseType" @on-change="houseTypeChange">
          <Radio label="1">自置物业</Radio>
          <Radio label="2">租房</Radio>
          <Radio label="3">宿舍</Radio>
          <Radio label="4">其他</Radio>
        </RadioGroup>
        <Input
          v-if="formData.userHouseVo.houseType == 4"
          :maxlength="20"
          v-model.trim="formData.userHouseVo.houseTypeDesc"
        ></Input>
      </FormItem>
      <FormItem label="身体健康情况" style="width: 100%">
        <RadioGroup v-model="formData.userHealthVo.healthStatus" @on-change="healthStatusChange">
          <Radio label="1">健康良好</Radio>
          <Radio label="2">有疾病</Radio>
        </RadioGroup>
         <Input
          v-if="formData.userHealthVo.healthStatus == 2"
          :maxlength="50"
          v-model.trim="formData.userHealthVo.healthDesc"
        ></Input>
      </FormItem>
      <FormItem label="每月收入" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="formData.userIncomeVo.incomeMonthly"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="家庭总收入" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="formData.userIncomeVo.incomeFamily"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="救助服务使用情况" style="width: 100%">
        <Input
          :maxlength="400"
          v-model.trim="formData.helpServiceContent"
          type="textarea"
          show-word-limit
          :rows="3"
          style="width: calc(100% - 200px)"
        ></Input>
      </FormItem>
    </div>
  </Form>
</template>

<script>
export default {
  props: ["formData","orgCodeList"],
  data() {
    return {
      otherOrgName: false,//其他所属社区
      orgName: "",//提交时赋值给orgName
      departmentList: [],  //所属部门
      nationList: [], //民族
    };
  },
  methods: {
    // 选择社区
    changeOrgCode() {
      this.formData.archiveDetailVo.orgName = '';
    },
    // 所属其他社区
    otherOrgNameFn(status) {
      if (status) {
        this.formData.archiveDetailVo.orgCode = "";
      }
    },
    houseTypeChange(val) {
      if (val != "4") {
        this.formData.userHouseVo.houseTypeDesc = "";
      }
    },
    healthStatusChange(val) {
      if(val != 2) {
        this.formData.userHealthVo.healthDetail = "";
      }
    },
  },
  created() {
     //获取民族
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "NATIONAKLITY"
      }).then(res => {
      if(res.code == 200) {
        this.nationList = res.dataList.map(item => {
          return {
            value: item.dictKey,
            label: item.dictValue
          }
        });
      }else {
        this.$Message.error({
          background: true,
          content: '获取民族信息失败'
        })
      }
    });
    //获取所属部门
  this.$get('/gx/pc/staff/selectUserPermissionDeptList',{
       custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      oemCode: parent.vue.oemInfo.oemCode,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then(res => {
      if(res.code == 200 && res.dataList) {
         this.departmentList = res.dataList.map(item => {
           return {
             value: item.orgCode,
             label: item.orgName
           }
         })
      }else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    })
  },
};
</script>
    
<style scoped lang='less'>
.content {
  .ivu-form-item {
    width: 100%;
    margin-bottom: 15px;
    .ivu-input-wrapper {
      width: 200px;
      /deep/input {
        border: 0;
        border-bottom: 1px solid #65656c;
        border-radius: 0;
      }
      /deep/input:hover,
      /deep/input:focus {
        border: 0;
        border-bottom: 1px solid #65656c;
        box-shadow: none;
      }
    }
  }
}
.validator {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>